import {ComponentProps, useCallback, useEffect, useMemo, useState} from "react";
import {NavLink} from "react-router-dom";

import {Web3Connection} from "../+web3-connection";
import {useMediaQuery} from "../../../hooks/useMediaQuery";
import {Flex} from "../flex";
import {Icon} from "../icon";
import {Logo} from "../logo";

import * as SC from "./index.styles";

interface HeaderProps {}

export const Header = (props: HeaderProps) => {
  const [open, setOpen] = useState(false);

  const isMobile = useMediaQuery(SC.headerBreakpoint);

  const toggleMenu = useCallback(() => {
    setOpen((_) => !_);
  }, [setOpen]);

  useEffect(() => {
    setOpen(false);
  }, [isMobile]);

  const menu = useMemo(() => {
    const Item = (props: ComponentProps<typeof SC.MenuItem>) => (
      <SC.MenuItem {...props} onClick={() => setOpen(false)} />
    );
    return (
      <>
        <Item to="/wallet">Wallet</Item>
        <Item to="/swap">Swap</Item>
        <Item to="/pool">Pools</Item>
        <Item to="/farm">Farms</Item>
        <Item to="/dao">Governance</Item>
        <Item to="/analytics">Analytics</Item>
        {/*<Item to="/rewards">Rewards</Item>*/}
      </>
    );
  }, []);

  return (
    <>
      <SC.Wrapper>
        <SC.Header>
          <NavLink to="/">
            <Logo />
          </NavLink>
          <SC.Menu>{menu}</SC.Menu>
          <Flex.Grow />
          <Web3Connection />
          <SC.MenuWrapper onClick={toggleMenu}>
            <Icon icon={!open ? "menu" : "close"} />
          </SC.MenuWrapper>
        </SC.Header>
      </SC.Wrapper>
      <SC.MobileMenu open={open}>
        <SC.Balance>
          <Web3Connection onlyBalance />
        </SC.Balance>
        <SC.MobileMenuWraper>{menu}</SC.MobileMenuWraper>
      </SC.MobileMenu>
    </>
  );
};
