/* eslint react-hooks/rules-of-hooks: 0 */

import {useMemo} from "react";
import {useGetChartV2Data} from "./chart-v2";
import {useGetChartV3Data} from "./chart-v3";
import {useGetGlobalData} from "./global";
import {useGetPairsV2Data} from "./pairs-v2";
import {useTopTokensV2Data} from "./top-tokens-v2";
import {useTopTokensV3Data} from "./top-tokens-v3";
import {useGetTransactionsV2Data} from "./transactions-v2";

export const sumObjs = <T extends Record<string, string | number>, K extends keyof T>(
  keys: K[],
  a: T,
  b: T = {} as any,
): Record<K, T[K]> => {
  const r = {...a};
  Object.entries(b).forEach(([key, value]) => {
    if (!keys.includes(key as K)) {
      delete r[key as K];
      return;
    }
    if (typeof r[key] === "number") r[key as K] += value as any;
  });
  return r;
};

export type AnalyticsData = ReturnType<typeof useAnalyticsData>;

export interface AnalyticsDataProps {
  load?: 'tokens' | 'pairs' | 'txs';
  disabled?: boolean;
}


export const useAnalyticsData = ({load, disabled}: AnalyticsDataProps = {}) => {
  const useLoadMiddleware = <T>(type: string | undefined, fn: (enabled: boolean, full?: boolean) => T) =>
    fn(!disabled && !!(!load || load === type), load === type);

  const globalDataQuery = useLoadMiddleware(undefined, (e: boolean) => useGetGlobalData(e));
  const globalData = globalDataQuery.data;

  const chartV2 = useLoadMiddleware(undefined, (e: boolean) => useGetChartV2Data(e));
  const chartV3 = useLoadMiddleware(undefined, (e: boolean) => useGetChartV3Data(e));

  const tTokensV2 = useLoadMiddleware("tokens", (e: boolean, full?: boolean) => useTopTokensV2Data(e, globalData, full));
  const tTokensV3 = useLoadMiddleware("tokens", (e: boolean, full?: boolean) => useTopTokensV3Data(e, globalData, full));

  const pairsV2 = useLoadMiddleware("pairs", (e: boolean, full?: boolean) => useGetPairsV2Data(e, globalData, full));

  const txV2 = useLoadMiddleware("txs", (e: boolean, full?: boolean) => useGetTransactionsV2Data(e, full));

  // Chart Data
  const chartData = useMemo(() => {
    if (!chartV2?.chartData?.length) {
      return [];
    }
    const v3Refs = {};
    chartV3.chartData.forEach((_) => (v3Refs[_.date] = _));
    return chartV2.chartData
      .map((_) => ({
        date: _.date,
        ...sumObjs(["liquidity", "volume"], _, v3Refs[_.date] as typeof _),
        v2: _,
        v3: v3Refs[_.date] as typeof _ | undefined,
      }))
      .reverse();
  }, [chartV2.chartData, chartV3.chartData]);

  // Stats
  const stats = useMemo(() => {
    const today = chartData[chartData.length - 1];
    return {
      fees: today?.volume * 0.005,
      ...globalData,
    };
  }, [globalData, chartData]);

  // Tokens
  const tokensData = useMemo(() => {
    if (!tTokensV2?.tokens?.length) return [];
    const v3Refs = {};
    tTokensV3?.tokens.forEach((_) => (v3Refs[_.symbol] = _));
    return tTokensV2.tokens
      .map((v2) => ({
        ...v2,
        ...sumObjs(["liquidityUsd", "volumeDayUsd", "txDayCount"], v2, v3Refs[v2.symbol] as typeof v2),
        v2,
        v3: v3Refs[v2.symbol] as typeof v2,
      }))
      .sort(({volumeDayUsd: a}, {volumeDayUsd: b}) => b - a);
  }, [tTokensV2, tTokensV3]);

  return {
    globalData: {
      isLoading: globalDataQuery.isLoading,
      data: globalData,
    },
    chart: {
      isLoading: chartV2.isLoading || chartV3.isLoading,
      data: chartData,
      v2: chartV2,
      v3: chartV3,
    },
    tokens: {
      isLoading: tTokensV2.isLoading || tTokensV3.isLoading,
      data: tokensData,
    },
    pairs: {
      isLoading: pairsV2.isLoading,
      data: pairsV2.pairs,
    },
    txs: {
      isLoading: txV2.isLoading,
      data: txV2.txs,
    },
    stats,
  };
};
