import {memo, useMemo} from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  Chip,
  Divider,
  Flex,
  IconAsset,
  Loading,
  Table,
  Text,
  Tooltip,
  toTableConfig,
} from "../../../../ui/components";
import { AnalyticsData } from "../../data";
import {useAnalytics} from "../../provider";
import {AnalyticsCell, AnalyticsHeader} from "./components/cell";
import { AnalyticsSection } from "./components/common-section";

import * as SC from "./index.styles";

interface AnalyticsOverviewPairsProps {
  full?: boolean;
  data?: AnalyticsData['pairs']['data'];
}

export const AnalyticsOverviewPairs = ({full, data: injectedData}: AnalyticsOverviewPairsProps) => {
  const {
    data: {pairs},
    extended,
  } = useAnalytics(full, 'pairs');
  const data = injectedData || (full ? extended.pairs : pairs.data);

  const config = useMemo(() => {
    return toTableConfig(data, [
      {
        index: "pair",
        key: true,
        header: "Pair",
        headerAlign: "left",
        align: "left",
        value: d => [d.token0, d.token1, d.id].join(),
        content: d => (
          <Flex gap={3}>
            <Flex>
              <IconAsset stack symbol={d.token0} button size="xs" />
              <IconAsset stack symbol={d.token1} button size="xs" />
            </Flex>
            <div>
              <Text h6>
                {d.token0}
                {' '}
                <Text inherit inline color="gray300">—</Text>
                {' '}
                {d.token1}
              </Text>

              <Text bodyRegular color="orchid" bodyMedium>
                <Link to={`/analytics/pair/${d.id}`}>
                  See details
                </Link>
              </Text>
            </div>
          </Flex>
        ),
      },
      {
        index: "liquidity",
        header: <AnalyticsHeader name="Liquidity" />,
        sort: 'desc',
        value: d => d.liquidity,
        content: d => <AnalyticsCell data={d} prop="liquidity" type="coin"/>,
      },
      {
        index: "volume",
        header: <AnalyticsHeader name="Volume" alt="24h" />,
        value: d => d.volumeDayUsd,
        content: d => <AnalyticsCell data={d} prop="volumeDayUsd" type="coin"/>,
      },
      {
        index: "fees",
        header: <AnalyticsHeader name="Fees" alt="24h" />,
        value: d => d.feesDayUsd,
        content: d => <AnalyticsCell data={d} prop="feesDayUsd" type="coin" change="apy"/>,
      },
    ]);
  }, []);

  return (
    <>
      <AnalyticsSection title="Top Pairs" href="/analytics/pairs" isPage={full} hideLink={!!injectedData}>
        <Table showLoading height={SC.getTableSize(full)} data={data} config={config} />
      </AnalyticsSection>
    </>
  );
};
