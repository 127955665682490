import {useMemo} from "react";
import { sumObjs } from ".";
import { AnalyticsGlobalData } from "./global";
import { usePairChartV2 } from "./pair-chart-v2";
import { useGetPairsV2Data } from "./pairs-v2";

import { useTokenDataV2 } from "./token-data-v2";
import { useTokenDataV3 } from "./token-data-v3";
import { useGetTransactionsFilteredV2Data } from "./transactions-filtered-v2";

export const usePairData = (globalData?: AnalyticsGlobalData, address?: string) => {
  const tokenPairs = useGetPairsV2Data(
    !!address,
    globalData,
    false,
    `where: {id: "${address}"}`,
    undefined,
    `
      reserve0
      reserve1
      a: token0 {
        address: id
        symbol
        name
        derivedETH
      }
      b: token1 {
        address: id
        symbol
        name
        derivedETH
      }
    `
  )

  const tokenTxs = useGetTransactionsFilteredV2Data(!!address, [address!])
  const pairChart = usePairChartV2(globalData, address)

  return {
    ready: !!tokenPairs.pairs?.length,
    pair: tokenPairs.pairs?.[0] || {},
    pairs: tokenPairs.pairs || [],
    txs: {
      isLoading: tokenTxs.isLoading,
      txs: tokenTxs.txs,
    },
    chart: {
      isLoading: pairChart.isLoading,
      ...pairChart.data,
    }
  };
};
