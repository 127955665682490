import {AnalyticsGlobalData} from "./global";

export const cleanData = ({data}: any) =>
  (data.pairs as any[]).map(({id, a, b, v, l, ...rest}) => ({
    id: id as string,
    token0: a.s as string,
    token0Data: a as any,
    token1: b.s as string,
    token1Data: b as any,
    volume: +v,
    liquidity: +l,
    rest,
  }))

type CleanData = ReturnType<typeof cleanData>[number];

const value = (_: number) => (isNaN(_) ? 0 : _);

export const transformPairs = ({ethPrice, ethDayPrice}: AnalyticsGlobalData = {} as any, pairs: any[] = []) =>
  pairs?.map?.(
    ({oneDayBefore, ...pair}: CleanData & {oneDayBefore: CleanData}) => {
      const volDay = value(+pair.volume - +oneDayBefore?.volume);
      return {
        ...pair,
        volumeDayUsd: volDay,
        feesDayUsd: volDay * 0.005,
        apy: ((volDay * 0.005) * 365) / pair.liquidity * 100,
      }
    }
  );
